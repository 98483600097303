.App {}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.no-shadow {
  border: none !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  color: #61dafb;
}

.App-link {
  /* color: #ed7a34; */
  cursor: pointer;
  color: #8760fb !important;
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  background: #fbfbfb;
}

table tr td {
  text-align: left;
}

.gradient-header .main-header {
  /* background:linear-gradient( 310.6deg, #f09537 37.8%, #FF4800 90.8%); */
  /* background: linear-gradient(to top,#FF4800,#f09537); */
  background: -webkit-gradient(linear, left bottom, left top, from(#FF4800), to(#ff5811));
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.05) 5px 5px 5px !important;
  width: 100% !important;
}

.color-leftmenu .main-sidebar-sticky {
  background: #003679;
  border-right: 1px solid transparent;
}

@media (min-width: 992px) {
  .main-sidebar {
    z-index: 500;
  }
}

.orange {
  color: #f8ebe5;
}

.flag-row-no-content {
  background-color: #fff !important;
  color: #000 !important;
}

.flag-row-no-content:hover {
  color: #334151;
  background-color: #fff;
}

.flag-row {
  background-color: rgba(240, 147, 55, 0.3) !important;
  color: #000 !important;
}

.flag-row a {
  color: #000000;
}

.small-caps {
  font-size: 11px !important;
}

.text-white {
  color: #ffffff;
}

.touch-close-menu {
  float: left;
  position: absolute;
  left: 5px;
  display: none;
}

.custom-menu-icon {
  font-size: 25px;
  color: #3f4651;
  cursor: pointer;
}

.notfound-color {
  color: #334151;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 1000px) {
  .touch-close-menu {
    display: inline;
  }
}

a:hover {
  text-decoration: none;
}

/* ----- NEW CSS ------ */

.table-header {
  display: flex;
  box-sizing: border-box !important;
  /* -ms-flex-align: center; */
  align-items: center !important;
  /* -ms-flex-wrap: wrap; */
  justify-content: space-between;
  margin: 0 !important;
  /* border: 1px solid red; */
}

.titles {
  /* border: 1px solid blue; */
  padding: 0 !important;
  padding: 0 !important;
}

.card .card-title {
  font-size: 1rem !important;
  font-weight: 500;
  /* border: 1px solid yellow; */
}

.buttons {
  /* border: 1px solid yellow; */
  margin: 0 !important;
  padding: 0 !important;
}

.card .card-description {
  color: rgb(118, 131, 143) !important;
  font-size: 0.79rem !important;
  padding: 0 !important;
  /* border: 1px solid yellow; */
  line-height: 1.3rem !important;
}

.see-all-btn {
  margin: 0 !important;
}

.task-icon-user {
  padding: 2px !important;
  border-radius: 50%;
  width: 9px !important;
  height: 9px !important;
}

.notify-icon-user {
  /* padding: 1px !important; */
  border-radius: 100%;
  width: 17px !important;
  height: 17px !important;
}

.unread-icon {
  position: absolute;
  bottom: 20px !important;
  left: 25px !important;
}

.notify-number {
  margin-bottom: 10px !important;
}

.notification-media {
  align-items: center !important;
}

/*  ----- 5.4 Image    ----- */

/* .main-img-user {
	width: 37px !important;
	height: 37px !important;
	border-radius: 7% !important;
} */

/* wrong Parking img */
.main-img-user:hover img {
  transform: scale(1.5);
  box-shadow: -7px -7px 7px rgba(0, 0, 0, 0.05), 7px 7px 7px rgba(0, 0, 0, 0.05) !important;
  transition: transform 0.2s;
}

.main-img-user img {
  border-radius: 12%;
  transition: transform 0.2s;
}

.rotate90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.model-content-image {
  background-color: transparent !important;
}

.btn {
  border-radius: 5px !important;
}

.card {
  border-radius: 5px !important;
  border: 1px solid transparent !important;
  transition: 0.4s;
}

.card-body {
  padding: 1.7rem !important;
}

.role-body {
  padding: 2rem 0 0 !important;
}

.rcent-data-body {
  padding: 1rem 0 0 !important;
}

/* .card-body+.card-body {
  border-top: none !important;
} */

@media (min-width: 992px) {
  .main-sidebar.side-menu {
    /* width: 250px !important; */
    box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05) !important;
  }
}

.main-sidebar-body {
  /* padding: 2px 1.5rem 1.5rem !important; */
  border: none !important;
}

.main-sidebar-body .nav-label {
  font-size: 0.6rem !important;
  margin: 0.75rem 0 !important;
  font-weight: bold !important;
  color: #FF4800 !important;
}

.main-sidebar-body .nav-link {
  height: 2.5rem;
  white-space: nowrap;
  /* margin: 0.1rem 0.5rem; */
}

.main-sidebar-sticky {
  border: none !important;
}

.pinned-card {
  color: #334151 !important;
}

.pinned-card:hover {
  color: #FF4800 !important;
  border-bottom: 0.1px solid #FF4800 !important;
  transition: 0.3s;
  cursor: pointer;
}

.pinned-row:hover,
.card-text-dark:hover {
  color: #FF4800 !important;
  transition: 0.3s;
  cursor: pointer;
}

/* form data */

.form-control {
  border-radius: 5px !important;
}

.allView-table {
  height: 62vh !important;
}

.nav-view {
  overflow: auto !important;
  height: 70vh !important;
  width: 100% !important;
}

.allView-config {
  height: 62.7vh !important;
  overflow: scroll !important;
  width: 100% !important;
  /* border: 1px solid red; */
}

.allView-config-cards {
  height: 77vh !important;
  overflow: scroll !important;
}

.perms-table {
  height: 60vh !important;
}

/* .map-card {
  height: 580px !important;
} */

/* .user-manager {
  height: 450px !important;
} */


/* .mtb-auto {
  width: 100% !important;
  height: 100% !important;
  margin: auto 0 !important;
  border: 1px solid red;
} */

.session-card {
  height: 460px !important;
}

.notify-height {
  height: 60vh !important;
  /* width: 100%; */
}

/* task icon css */

.task-icon {
  padding: 2px !important;
  border-radius: 35%;
  width: 12px !important;
  height: 12px !important;
}

.task-icon-user {
  padding: 2px !important;
  border-radius: 50% !important;
  width: 9px !important;
  height: 9px !important;
}

.hr-table {
  /* color: #fff; */
}

.switch-tabler {
  margin: 0 auto !important;
}

.hr-table th,
.hr-table td {
  /* padding: 0 !important; */
  vertical-align: middle !important;
  /* border-top: 1px solid #f3f3f328; */
}

.table-hovered tbody tr:hover {
  color: #212529 !important;
  background-color: #eaeaf149 !important;
}

.user-table {
  height: 300px !important;
}

.border-right {
  /* border-right: 1px solid #e1e6f1 !important; */
  /* height: 25px !important; */
  /* border-right-width: 100px !important; */
}

.divider {
  display: inline-block;
  width: 0;
  height: 5em;
  border-left: 1px solid #e1e6f1;
  /* border-right: 1px solid #e1e6f1; */
}

.new-chart {
  /* border-right: 1px solid #e1e6f1 !important;
  line-height: 10px !important; */

  /* position: relative !important; */
  /* height: auto !important; */

  width: 100% !important;
  margin-bottom: 1rem !important;
  margin-top: 1.7rem !important;
}

/* .metismenu-state-icon,

@media (max-width: 991.98px) {
  .more {
    justify-content: space-between;
    justify-content: center;
    align-items: center;
  }

  .tab-card-3 {
    margin: 0 10px 20px 0;
  }
}

.no-bg {
  background-color: transparent;
  box-shadow: none !important;
}

.add-bg {
  color: #fff;
  background-size: cover;
}

*/

.table,
.new-chart,
.tab4departments {
  width: 100% !important;
  margin-bottom: 1rem !important;
  margin-top: 1.7rem !important;
  /* color: #212529 !important;
  border-collapse: collapse;
  border: 1px solid #eef0fb */
}

/* .table th, */
.table td,
.table th {
  /* padding: 0.6rem 0 0.6rem 1rem !important; */
  /* display: flex; */
  /* align-items: center !important;
  border-top: 1px solid #f3f3f3 !important; */
  /* text-align: center;  */
  vertical-align: middle !important;
  /* font-size: 14px !important; */
}

/*
.main-table-reference>thead>tr>th, .main-table-reference>thead>tr>td {
	background-color: whitesmoke;
	color: #000 !important;
	font-size: 12px !important;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 1px;
	border-bottom: 0;
}

.table thead th {
  border-top: 0 !important;
  font-weight: 700 !important;
  font-size: 0.7rem !important;
}

.main-table-reference th,
.main-table-reference td {
  border-left: none !important;
  border-right: none !important;
} */

/* .table tbody + tbody {
  border-top: 2px solid #f3f3f3;
} */

.table thead th {
  font-weight: 700;
  font-size: 0.7rem;
}

/*SCROLL BARS**************/
.main-sidebar-body {
  height: 100% !important;
  overflow-y: auto;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #ffffff #ffffff;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 0;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 20px;
  border: 3px solid #ffffff;
}

/*ENDSSCROLL BARS**************/

/******HIDDING NUMBER INPUT ARROW CONTROLS *******/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/******END OF HIDDING NUMBER INPUT ARROW CONTROLS *******/

/**********SWITCH BOXES*************/
.onoffswitch-checkbox:checked+.onoffswitch-label {
  background-color: #ef8a35;
}

.onoffswitch-checkbox:checked+.onoffswitch-label,
.onoffswitch-checkbox:checked+.onoffswitch-label:before {
  border-color: #ef8a35;
}

.onoffswitch2-checkbox:checked+.onoffswitch2-label {
  background-color: #ef8a35;
}

.onoffswitch2-checkbox:checked+.onoffswitch-label2,
.onoffswitch2-checkbox:checked+.onoffswitch2-label:before {
  border-color: #ef8a35;
}

/************************ENDS SWITCH BOX************************/

/**********SWITCH SMS BOXES*************/
.onoffswitchsms-checkbox:checked+.onoffswitchsms-label {
  background-color: rgb(135, 96, 251);
}

.onoffswitchsms-checkbox:checked+.onoffswitchsms-label,
.onoffswitchsms-checkbox:checked+.onoffswitchsms-label:before {
  border-color: rgb(135, 96, 251);
}

.onoffswitch3-checkbox:checked+.onoffswitch3-label {
  background-color: rgb(135, 96, 251);
}

.onoffswitch3-checkbox:checked+.onoffswitchsms-label2,
.onoffswitch3-checkbox:checked+.onoffswitch3-label:before {
  border-color: rgb(135, 96, 251);
}

/************************ENDS SWITCH BOX************************/

.minor-tag {
  font-size: 10px;
}

/* ----- chart.js css -----*/

.chart-wrapper {
  padding: 2%;
  display: inline-block;
}

.main.chart-wrapper {
  width: 96%;
  height: 400px;
}

.sub.chart-wrapper {
  width: 29%;
  height: 300px;
}

/* Simple responsivenss example */
@media (max-width: 700px) {
  .sub.chart-wrapper {
    width: 96%;
  }
}

/* --- color for card text --- */

/* .card-text-dark {
  color:#334151;
} */

.page-link,
.link {
  cursor: pointer;
}

.table.table-ellipsis tbody td {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table.table-ellipsis tbody td:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
  cursor: pointer;
}

.bg-company {
  background-color: #FF4800;
  color: #ffffff !important;
}

.bg-company .text-muted {
  color: #ffffff !important;
}

.bg-company:hover {
  color: #FF4800 !important;
  border-bottom: 0.1px solid #FF4800 !important;
  transition: 0.3s;
  cursor: pointer;
  background-color: #ffffff;
}

.bg-company:hover .text-muted {
  color: #000000 !important;
}

.occupancy-table {
  text-align: center !important;
}

.bg-company-text {
  color: #FF4800 !important;
  border-bottom: 0.1px solid #FF4800 !important;
  transition: 0.3s;
  cursor: pointer;
  background-color: #ffffff;
}

.bg-company-block {
  background-color: #FF4800;
  color: #ffffff !important;
}

.bg-company-block .text-muted,
.bg-company-text .text-muted {
  color: #000000 !important;
}

.hot-tile {
  background-color: #FF4800;
  color: #ffffff !important;
}

.hot-tile .text-muted {
  color: #ffffff !important;
}

.hot-tile:hover {
  color: #FF4800 !important;
  border-bottom: 0.1px solid #FF4800 !important;
  transition: 0.3s;
  cursor: pointer;
  background-color: #ffffff;
}

.hot-tile:hover .text-muted {
  color: #dedede !important;
}

/* .scroll-widget2 {
  width: 100%;
  height: 485px;
  overflow: hidden;
} */

.no-shadow {
  box-shadow: none !important;
}

.config-component-item a:before {
  content: "\e92f";
  margin-right: 8px;
  position: relative;
  font-family: "feather" !important;
  color: #68798b;
}

.main-content-left-components .config-component-item .nav-link {
  padding: 0;
  display: block;
  font-size: 15px;
  color: #596882;
}

.main-content-left-components .config-component-item .nav-link+.nav-link {
  margin-top: 7px;
}

.main-content-left-components .config-component-item .nav-link:hover {
  color: #8760fb;
}

.main-content-left-components .config-component-item .nav-link.active {
  color: #8760fb;
  font-weight: 500;
}

.icon-service2 {
  display: inline-flex;
  width: 5.5rem !important;
  height: 5.5rem !important;
  padding: 1.1rem !important;
  margin-bottom: 0.5rem !important;
}

.icon-service2 i {
  font-size: 3.2rem !important;
}

.access-perms {
  height: 100vh !important;
  /* width: 100%;
  height: 100vh; */
  /* margin: 0; */
  /* padding: 0; */
}

.h-55 {
  /* height: 55% !important; */
  margin: 150px 0;
}

/* .access-perms .access-content {
  height: 50% !important;
  margin: auto 0 !important;
} */

.notfound-color {
  color: #334151;
}

.pinn-card {
  color: #334151 !important;
}

.pinn-card:hover {
  color: #FF4800 !important;
  /* border-bottom: 0.1px solid #FF4800 !important; */
  transition: 0.3s;
  cursor: pointer;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-easing="ease"],
body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s;
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
}

.mb-35 {
  margin-bottom: 35px;
}

.block-style-twentyFour {
  background: #fff;
  padding: 40px 43px 50px;
  box-shadow: 0 4px 0 #e9f3fa;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

/* element {
  background: rgb(63, 225, 147) none repeat scroll 0% 0%;
} */
.block-style-twentyFour .icon {
  width: 70px;
  height: 70px;
  border-radius: 20px;
}

.block-style-twentyFour .text {
  width: calc(100% - 70px);
  padding-left: 32px;
}

/* new styles for code areas */

.docs-coderail {
  color: white;
  background-color: #233659;
  padding-bottom: 10rem;
  border-radius: 0;
}

.docs-layout-column {
  flex: 1;
  overflow-x: hidden;
}

@media screen and (min-width: 900px) {
  .docs-layout-column {
    height: calc(100vh - 3rem);
    overflow-y: auto;
  }
}

.twlo-code {
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  overflow: auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

.twlo-code-header {
  background-color: #152748;
  box-shadow: inset 0 -2px 0 -1px rgb(148 151 155 / 20%);
  position: sticky;
  top: 0;
  z-index: 10;
  color: white;
}

.twlo-code-header__title {
  display: flex;
  align-items: center;
  position: relative;
  height: 5rem;
  padding: 1.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.55;
  line-height: 1.2;
}

.twlo-code-header__title .twlo-icon-interface-dropdown {
  margin: 0 1rem;
  color: rgba(148, 151, 155, 0.75);
}

svg {
  max-height: 100%;
}

.twlo-code__sample,
.twlo-code__output {
  position: relative;
  background: #233659;
  overflow-x: auto;
  text-align: left;
  z-index: 1;
}

.wrapper__content {
  margin: 25px 35px;
}

/* .buttons-html5 {
  background-color: #000; 
  margin-top: 10px;
}

.buttons-print {
  margin-top: 10px;
  background-color: #334151;
  color: #fff;
}
.buttons-csv {
  background-color: #01b8ff;
  color: #fff;
}

.buttons-excel {
  background-color: #03c895;
  color: #fff;
}
.buttons-copy {
  background-color: #e1e6f1;
  color: #000;
}

.buttons-pdf {
  background-color: #8760fb;
  color: #fff;
} */

.buttons-html5:hover,
.buttons-print:hover,
.buttons-csv:hover,
.buttons-excel:hover,
.buttons-copy:hover,
.buttons-pdf:hover {
  background-color: #d3d8e4 !important;
  color: #000 !important;
}

.buttons-html5,
.buttons-print,
.buttons-csv,
.buttons-excel,
.buttons-copy,
.buttons-pdf {
  background-color: #fbfbfb !important;
  color: #000 !important;
  /* margin-right: 5px !important; */
  border: 1px solid #e1e6f1 !important;
  border-radius: 0 !important;
}

.dataTables_wrapper .row div .dataTables_filter label {
  display: flex !important;
  align-items: center !important;
}

.dataTables_wrapper .row div .dataTables_filter label input {
  margin-left: 17px !important;
  width: 100% !important;
  padding: 19.5px !important;
  border-color: rgb(200, 200, 200) !important;
}


.dataTables_wrapper .row div .dataTables_filter {
  margin-top: -1px !important;
}

.dataTables_wrapper .row div .dt-buttons,
.dataTables_wrapper .row div .dataTables_filter {
  margin-top: 2px !important;
}

.dataTables_wrapper .row div .dataTables_info,
.dataTables_wrapper .row div .dataTables_paginate {
  margin-top: 7px !important;
  /* border: 1px solid red; */
}

.dataTables_wrapper .row div .dataTables_info {
  padding: 5px 0 5px 0 !important;
}


.dataTables_wrapper,
.dt-bootstrap4,
.no-footer {
  padding: 0 !important;
  margin: 0 !important;
  /* border: 1px solid green !important; */
}

.dataTables_wrapper .row div .table {
  margin-top: -5px !important;
}



.w-10 {
  width: 140px !important;
  border-left: 1px solid rgb(243, 243, 243);
}

.w-7 {
  width: 100px !important;
  /* border-left: 1px solid rgb(243, 243, 243); */
}

.wAbort {
  width: 700px !important;
  /* border-left: 1px solid rgb(243, 243, 243); */
}

/* .mt20 {
  margin-top: -20px !important;
} */

.mt21 {
  margin-top: -45px !important;
}

.mt22 {
  margin-top: -60px !important;
}

.flex-flow {
  flex-direction: column !important;
}

.opacity-5 {
  opacity: 0.6 !important;
}

.customButtonList {
  list-style-type: none;
}

.customButtonList li {
  display: inline;
  float: left;
  margin-left: 0.5rem;
}

.notify-text {
  color: rgb(77, 66, 66) !important;
  /* font-weight: bold !important; */
}

.notify-text:hover {
  color: rgb(95, 95, 243) !important;
}


/* .dataTables_filter label {
  display: flex !important;
  align-items: center !important;
}

.dataTables_filter label input {
  margin-left: 17px !important;
  width: 35% !important;
}

.dataTables_wrapper .row div .dt-buttons,
.dataTables_wrapper .row div .dataTables_filter {
  margin-top: 1.7rem !important;
}

.dataTables_wrapper .row div .table {
  margin-top: 0 !important;
} */

.mr-25 {
  margin-right: 25px !important;
}

.w-100px {
  width: 650px !important;
}

.border-1 {
  border: 1px solid rgb(223, 221, 221);
  font: 1em sans-serif;
  margin-right: 25px;

}

.wp-cover {
  overflow: hidden;
  width: 170px !important;
  height: 270px !important;
  border-radius: 5px;
}

.towID-cover {
  overflow: hidden;
  width: 170px !important;
  height: 198px !important;
  border-radius: 5px;
}

.wp-img {
  width: 100% !important;
}

.mt-15 {
  margin-top: 0.35rem !important;
}

.filter-example {
  padding: 0 0 1rem;
  /* border: 1px solid #e1e6f1; */
  font-size: 0.9375rem;
}

/* .custom-leftnav {
  position: fixed !important;
  float: right !important;
  right: 0 !important;
} */
@media print {
  /* Style for the timestamp when printing */
  div {
      text-align: center;
      margin-top: 20px;
      font-style: italic;
      color: #888;
  }
}