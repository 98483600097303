.marquee-list{
    position: relative; 
    overflow: hidden; 
    float: left; 
    font: bold 10px Verdana; 
    list-style-type: none; 
    margin: 0px; 
    padding: 0px; 
    transition-timing-function: linear; 
    transition-duration: 4.373s; 
  }
  
  .marquee-list li{
    white-space: nowrap; 
    padding: 0px 7px; 
    display: inline !important;
    font-size: 20px;
  }
  
  .marquee-container{
    height: auto; overflow: hidden;
  }
  
  .slimScrollDiv-custom{
    position: relative; 
    overflow: hidden; 
    width: auto !important; 
    height: 100%;
  }
  
  .nk-nav-scroll-custom{
    overflow: hidden;
     width: auto !important;
     height: 100% !important;
  }

  .crypto-ticker{
    /* background:rgba(0,0,0,.5); */
    margin-top:5px;
    padding:7px 5px;
    /* border-radius:3px;
    box-shadow:0 0 35px 0 rgba(154,161,171,.15) */
}

[data-theme-version=dark] 
.crypto-ticker{background:#131633}

.incoming-card {
  border-radius: 5px !important;
}
  