.clamp-color {
    background-color: white;
    border: 1px solid #28a745;
    font-weight: bold;
    color: #28a745;
    /* font-size: 12px; */
}

.unclamp-tow-color {
    background-color: white;
    border: 1px solid #ff5811;
    font-weight: bold;
    color: #ff5811;
    /* font-size: 12px; */
}

.partial-pay-color {
    background-color: white;
    border: 1px solid #007bff;
    ;
    font-weight: bold;
    color: #007bff;
    /* color: #17a2b8; */
    /* font-size: 12px; */
}

.not-towed-color {
    background-color: white;
    border: 1px solid black;
    ;
    font-weight: bold;
    color: black;
    /* color: #17a2b8; */
    /* font-size: 12px; */
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.hidden-tooltip {
    color: #fff;
    display: flex;
    justify-content: center;
    width: 800px;
    margin-left: 50px;
    height: 60px;
}