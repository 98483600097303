.receipt-container {
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
}

.company-info, .customer-info, .qr-code, .parking-info, .tax-info, .footer {
    margin-bottom: 20px;
}

.qr-code img {
    max-width: 100%;
    height: auto;
}

.footer {
    text-align: center;
}

.receipt-container h1 {
    font-size: 18px;
    margin-bottom: 10px;
}

.receipt-container h2 {
    font-size: 16px;
    background-color: #333;
    color: white;
    padding: 5px;
    margin-bottom: 10px;
}

.header h2 {
    background-color: transparent;
}