@import '../css/clamp-btns.css';

@font-face {
  font-family: Rubik-Regular;
  src: url('../fonts/Rubik/Rubik-Regular.ttf');
}

@font-face {
  font-family: Rubik-Medium;
  src: url('../fonts/Rubik/Rubik-Medium.ttf');
}

@font-face {
  font-family: Rubik-SemiBold;
  src: url('../fonts/Rubik/Rubik-SemiBold.ttf');
}

@font-face {
  font-family: Rubik-Bold;
  src: url('../fonts/Rubik/Rubik-Bold.ttf');
}

@font-face {
  font-family: Rubik-ExtraBold;
  src: url('../fonts/Rubik/Rubik-ExtraBold.ttf');
}

@font-face {
  font-family: Rubik-Black;
  src: url('../fonts/Rubik/Rubik-Black.ttf');
}




*,
html,
body {
  margin: 0;
  padding: 0;
  /* font-family: Rubik-Regular; */
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #fbfbfb;
}

/* h2,
h3,
h4,
h5 {
  font-family: Rubik-Medium !important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* CSS FOR SUBMENU 1 */

.side-menu .nav-sub1 {
  max-height: 0;
  overflow: hidden;
  transition: all 0.9s;
}

.main-sidebar-body .nav-sub1 {
  list-style: none;
  padding: 0;
  margin-left: 15px;
}

.side-menu .nav-sub-link.with-sub1 {
  transition: all .25s;
}

.dark-theme .main-sidebar-body .nav-sub-link {
  color: #bfbfe0;
}

.nav-sub-link.with-sub1 {
  position: relative;
}