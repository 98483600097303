.invoice-POS{
    /* box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5); */
    padding:2mm;
    margin: 0 auto;
    /* width: 44mm; */
    width: 100%;
    background: #FFF;
}
.invoice-POS ::selection {background: #f31544; color: #FFF;}
  ::moz-selection {background: #f31544; color: #FFF;}
.invoice-POS h1{
    font-size: 1.5em;
    color: #222;
  }
  .invoice-POS h2{font-size: .9em;}
  .invoice-POS h3{
    font-size: 1.2em;
    font-weight: 300;
    line-height: 2em;
  }
  .invoice-POS p{
    font-size: .7em;
    color: #666;
    line-height: 1.2em;
  }
   
  .invoice-POS #top, #mid,#bot{ /* Targets all id with 'col-' */
    border-bottom: 1px solid #EEE;
  }
  
  .invoice-POS #top{min-height: auto;}
  .invoice-POS #mid{min-height: 50px; text-align: center;} 
  .invoice-POS #bot{ min-height: auto;}
  
  .invoice-POS #top .logo{
      height: 60px;
      width: 60px;
      background: url(http://parking.thrivetecdev.com/PMIS-API/assets/img/logo_d.png) no-repeat;
      background-size: 60px 60px;
  }
  .invoice-POS .clientlogo{
    float: left;
      height: 60px;
      width: 60px;
      background-size: 60px 60px;
    border-radius: 50px;
  }
  .invoice-POS .info{
    display: block;
    margin-left: 0;
  }
  .invoice-POS .title{
    float: right;
  }
  .invoice-POS .title p{text-align: right;} 
  .invoice-POS table{
    width: 100%;
    border-collapse: collapse;
  }
  .invoice-POS td{
    /* //padding: 5px 0 5px 15px; */
    /* //border: 1px solid #EEE */
  }
  .invoice-POS .tabletitle{
    /* //padding: 5px; */
    font-size: .5em;
    background: #EEE;
  }
  .invoice-POS .service{border-bottom: 1px solid #EEE;}
  .invoice-POS .item{width: 24mm;}
  .invoice-POS .itemtext{font-size: .6em;}
  
  .invoice-POS #legalcopy{
    margin-top: 5mm;
  }
  .invoice-POS .company-info{
      margin-top: -8px;
  }

  .invoice-POS .doc-type{
    margin-top: -14px;
    padding-bottom: 10px;
    font-size: 11px;
    text-transform: uppercase;
  }
    
    
  